import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_5/sub_1/slide1';
import WrapperMobile from 'src/slides/mobile/en/chapter_5/sub_1/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Edgar Vincent D’Abernon | The Battle of Warsaw" description="Who was the man who called the Battle of Warsaw the 18th decisive battle in the history of the world?" lang="en" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Edgar Vincent D’Abernon | The Battle of Warsaw" description="Who was the man who called the Battle of Warsaw the 18th decisive battle in the history of the world?" lang="en" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
